class APIError extends Error {
  constructor(response) {
    const { message } = response.data;
    super(message);
    this.constructor = APIError;
    this.message = message;
    this.statusCode = response.status;
  }
}

export default APIError;
