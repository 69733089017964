import APIRoute from '../APIRoute';

class CreateConfigRoute extends APIRoute {
  constructor(options) {
    const routeOptions = {
      auth: false,
      method: 'POST',
      template: `/api/configs`,
      ...options,
    };
    super(routeOptions);
  }
}

export default CreateConfigRoute;
