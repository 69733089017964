import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { ArrowCounterClockwiseGlyph, ArrowLeftGlyph, ThreeDGlyph, RotateGlyph } from './Icons';

const DEFAULT_BUTTON_STYLES = `
text-decoration: none;
display: flex;
align-items: center;
justify-content: center;
gap: 0.625rem;
padding: 1rem 1.15rem 0.8rem;
color: var(--white);
--glyph-color: var(--white);
text-transform: uppercase;
font: var(--button);
letter-spacing: var(--button-letterspacing);
text-decoration: none;
background-color: var(--dark-tyrian-blue);
border-radius: 0.1875rem;
text-align: center;
&:hover,
&:focus-visible {
  color: var(--electric-lime);
  --glyph-color: var(--electric-lime);
}
&:active {
  color: var(--dark-tyrian-blue);
  --glyph-color: var(--dark-tyrian-blue);
  background-color: var(--electric-lime);
}
&:disabled {
  background-color: var(--gray);
  cursor: not-allowed;
  &:hover,
  &:focus-visible {
    color: var(--white);
  }
}
`;

const DefaultButton = styled.a`
  ${DEFAULT_BUTTON_STYLES}
`;

const DefaultUIButton = styled.button`
  ${DEFAULT_BUTTON_STYLES}
  width: 100%;
`;

const DefaultSpanButton = styled.span`
  ${DEFAULT_BUTTON_STYLES}  
  width: 100%;  
`;

const StandardButton = styled(DefaultButton)`
  text-align: center;
`;

const StandardUIButton = styled(DefaultUIButton)`
  text-align: center;
`;

const StandardSpanButton = styled(DefaultSpanButton)`
  text-align: center;
`;

const SmallButton = styled(DefaultButton)`
  letter-spacing: 0.02em;
  padding: 0.5rem 0.625rem;
  background-color: rgba(255, 255, 255, 0.1);
`;

const SmallUIButton = styled(DefaultUIButton)`
  width: auto;
  letter-spacing: 0.02em;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
`;

const TertiaryButton = styled(DefaultButton)`
  padding: 0.85rem 1.15rem 0.75rem;
  color: var(--rtg-dark-blue);
  --glyph-color: var(--rtg-dark-blue);
  background-color: var(--electric-lime);
  box-shadow: inset 0 0 1px rgba(24, 26, 33, 0.25);
  &:hover,
  &:focus-visible {
    color: var(--rtg-dark-blue);
    --glyph-color: var(--rtg-dark-blue);
    background-color: var(--white);
  }
`;

const TertiaryUIButton = styled(DefaultUIButton)`
  padding: 0.5rem 1.15rem;
  color: var(--rtg-dark-blue);
  --glyph-color: var(--rtg-dark-blue);
  background-color: var(--electric-lime);
  box-shadow: inset 0 0 1px rgba(24, 26, 33, 0.25);
  &:hover,
  &:focus-visible {
    color: var(--rtg-dark-blue);
    --glyph-color: var(--rtg-dark-blue);
    background-color: var(--white);
  }
`;

const OUTLINE_STYLES = `
  text-decoration: none;
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: center;
  gap: 0.35rem;
  padding: 0.25rem 0.75rem 0.25rem 0.875rem;
  min-height: 2.25rem;
  color: var(--rtg-dark-blue);
  --glyph-color: var(--rtg-dark-blue);
  text-transform: uppercase;
  font: var(--button);
  letter-spacing: var(--button-letterspacing);
  text-decoration: none;
  background-color: var(--white);
  border-radius: 0.3125rem;
  text-align: center;
  border: 1px solid var(--rtg-blue);
  &:hover,
  &:focus-visible {
    color: var(--electric-lime);
    --glyph-color: var(--electric-lime);
    background-color: var(--rtg-dark-blue);
  }
  &:active {
    color: var(--dark-tyrian-blue);
    --glyph-color: var(--dark-tyrian-blue);
    background-color: var(--electric-lime);
  }
  &:disabled {
    border-color: var(--gray);
    color: var(--dark-gray);
    cursor: not-allowed;
    &:hover,
    &:focus-visible {
      background-color: var(--white);
      border-color: var(--gray);
      color: var(--dark-gray);
    }
  }
`;

const OutlineButton = styled.a`
  ${OUTLINE_STYLES};
`;

const OutlineUIButton = styled.button`
  ${OUTLINE_STYLES};
`;


const Icon = styled.span`
  display: block;
  svg {
    vertical-align: bottom;
  }
`;

const ICONS_MAP = {
  reload: ArrowCounterClockwiseGlyph,
  back: ArrowLeftGlyph,
  threed: ThreeDGlyph,
  rotate: RotateGlyph,
};

const GREEN_BUTTON_STYLES = `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  height: 3.75rem;
  border-radius: 1.875rem;
  background-color: rgba(156,232,10,.7);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--rtg-dark-blue);
  font: var(--button);
  letter-spacing: var(--button-letterspacing);
`;

const GreenButton = styled.a`
  ${GREEN_BUTTON_STYLES};
`;

const GreenUIButton = styled.button`
  ${GREEN_BUTTON_STYLES};
  width: 100%;
`;

const INVISIBLE_BUTTON_STYLES = `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--rtg-dark-blue);
  font: var(--button);
  letter-spacing: var(--button-letterspacing);
`;

const InvisibleButton = styled.a`
  ${INVISIBLE_BUTTON_STYLES};
`;

const InvisibleUIButton = styled.button`
  ${INVISIBLE_BUTTON_STYLES};
  width: 100%;
`;


// 'Link' Buttons

export const Standard = ({ children, href, disabled, icon = false }) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <Link href={href} passHref legacyBehavior>
      <StandardButton disabled={disabled || null}>
        {IconGlyph && (
          <Icon>
            <IconGlyph />
          </Icon>
        )}
        {children}
      </StandardButton>
    </Link>
  );
};

export const Small = ({ children, href, disabled }) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <SmallButton disabled={disabled || null}>{children}</SmallButton>
    </Link>
  );
};

export const Tertiary = ({ children, href, disabled, icon = false }) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <Link href={href} passHref legacyBehavior>
      <TertiaryButton disabled={disabled || null}>
        {IconGlyph && (
          <Icon>
            <IconGlyph />
          </Icon>
        )}
        {children}
      </TertiaryButton>
    </Link>
  );
};

export const Outline = ({ children, href, disabled, icon = false }) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <Link href={href} passHref legacyBehavior>
      <OutlineButton disabled={disabled || null}>
        {IconGlyph && (
          <Icon>
            <IconGlyph />
          </Icon>
        )}
        {children}
      </OutlineButton>
    </Link>
  );
};

export const Green = ({
  children,
  href,
  disabled,
  icon = false
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <Link href={href} passHref legacyBehavior>
      <GreenButton
        disabled={disabled || null}
      >
        {IconGlyph && (
          <Icon>
            <IconGlyph />
          </Icon>
        )}
        {children}
      </GreenButton>
    </Link>
  );
};

export const Invisible = ({
  children,
  href,
  disabled,
  icon = false
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <Link href={href} passHref legacyBehavior>
      <InvisibleButton
        disabled={disabled || null}
      >
        {IconGlyph && (
          <Icon>
            <IconGlyph />
          </Icon>
        )}
        {children}
      </InvisibleButton>
    </Link>
  );
};

// UI Buttons

export const StandardUI = ({
  children,
  clickHandler,
  disabled,
  type = 'button',
  icon = false,
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <StandardUIButton
      onClick={clickHandler}
      disabled={disabled || null}
      type={type}
    >
      {IconGlyph && (
        <Icon>
          <IconGlyph />
        </Icon>
      )}
      {children}
    </StandardUIButton>
  );
};

export const SmallUI = ({
  children,
  clickHandler,
  disabled,
  type = 'button',
  icon = false,
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <SmallUIButton onClick={clickHandler} disabled={disabled || null}>
      {IconGlyph && (
        <Icon>
          <IconGlyph />
        </Icon>
      )}
      {children}
    </SmallUIButton>
  );
};

export const TertiaryUI = ({
  children,
  clickHandler,
  disabled,
  type = 'button',
  icon = false,
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <TertiaryUIButton onClick={clickHandler} disabled={disabled || null} type={type}>
      {IconGlyph && (
        <Icon>
          <IconGlyph />
        </Icon>
      )}
      {children}
    </TertiaryUIButton>
  );
};

export const OutlineUI = ({
  children,
  clickHandler,
  disabled,
  type = 'button',
  icon = false,
  style,
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <OutlineUIButton style={style} onClick={clickHandler} disabled={disabled || null} type={type}>
      {IconGlyph && (
        <Icon>
          <IconGlyph />
        </Icon>
      )}
      {children}
    </OutlineUIButton>
  );
};

export const GreenUI = ({
  children,
  clickHandler,
  disabled,
  type = 'button',
  icon = false
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <GreenUIButton
      onClick={clickHandler}
      disabled={disabled || null}
      type={type}
    >
      {IconGlyph && (
        <Icon>
          <IconGlyph />
        </Icon>
      )}
      {children}
    </GreenUIButton>
  );
};

export const InvisibleUI = ({
  children,
  clickHandler,
  disabled,
  type = 'button',
  icon = false
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <InvisibleUIButton
      onClick={clickHandler}
      disabled={disabled || null}
      type={type}
    >
      {IconGlyph && (
        <Icon>
          <IconGlyph />
        </Icon>
      )}
      {children}
    </InvisibleUIButton>
  );
};

// Span Buttons

export const StandardSpan = ({
  children,
  icon = false,
}) => {
  const IconGlyph = icon ? ICONS_MAP[icon] : null;
  return (
    <StandardSpanButton className='span-button'     
    >
      {IconGlyph && (
        <Icon>
          <IconGlyph />
        </Icon>
      )}
      {children}
    </StandardSpanButton>
  );
};