import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useLocalStorage } from '../hooks/useLocalStorage';
import * as Sentry from '@sentry/nextjs';
import { WEB_USER_STORE_NUMBER } from '../../constants';
const AppContext = createContext();

export function AppWrapper({ children }) {
  const router = useRouter();
  const { slsnumber, store, web, zipCode, color, session } = router.query;
  const [availableColors, setAvailableColors] = useState(null);
  const [apiProducts, setApiProducts] = useState(null);
  const [activeColor, setActiveColorInState] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [salesId, setSalesId] = useState(undefined);
  const [webUser, setWebUser] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [storeNumber, setStoreNumber] = useLocalStorage(
    'rtg-store-number',
    null
  );
  const [zip, setZip] = useLocalStorage(
    'rtg-zip',
    null
  );  
  
  // if slsnumber is in url
  useEffect(() => {
    if(slsnumber) {
      setSalesId(slsnumber);
    }
  }, [slsnumber, setSalesId]);

  // if store is in url
  useEffect(() => {
    if (store) {
      setStoreNumber(store);
      // if there's a store in the query string, set the zip to null
      setZip(null);
    }
  }, [setStoreNumber, store]);

  // if zipCode is in url
  useEffect(() => {
    if (zipCode) {
      setZip(zipCode);
      // also if zipCode, set the store number to 970, an RTG web store
      setStoreNumber(WEB_USER_STORE_NUMBER);
    }
  }, [setZip, zipCode, setStoreNumber]);

  // if web is in url
  useEffect(() => {
    if(web) {
      setWebUser(web);
    }
  }, [web, setWebUser]);

  //if sessionId is in url
  useEffect(() => {
    if(session) {
      setSessionId(session);
    }
  }, [session]);
  
  const setActiveColor = useCallback(
    (color) => {
      setActiveColorInState(color);
      Sentry.setTag('activeColor', color);
    },
    [setActiveColorInState]
  );
  
  // if color is in url
  useEffect(() => {
    if(color) {
      setActiveColor(color);
    }
  }, [color, setActiveColor]);

  let sharedState = {
    activeColor,
    apiProducts,
    availableColors,
    imagesLoaded,
    salesId,
    webUser,
    sessionId,
    zip,
    setZip,
    setWebUser,
    setSalesId,
    setActiveColor,
    setApiProducts,
    setAvailableColors,
    setImagesLoaded,
    setStoreNumber,
    setSessionId,
    storeNumber,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
