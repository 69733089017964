import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';

import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_IMAGE_ID_PREFIX,
} from '../../constants';

const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_CLOUD_NAME,
  },
});

export const cloudinaryImage = ({
  imageId,
  format = 'webp',
  quality = 75,
  width,
  height,
}) => {
  const img = cld.image(`${CLOUDINARY_IMAGE_ID_PREFIX}/${imageId}`);
  img.format(format);
  img.quality(quality);
  img.resize(fill().width(width).height(height));

  return img;
};

export const cloudinaryImageSrc = (
  imageId,
  format = 'webp',
  quality = 75,
  width,
  height,
) => {
  const src = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/f_${format}/q_${quality}/c_fill,h_${height},w_${width}/c_scale,w_${width}/v1/rtg40200-dev/configurator/${imageId}?_a=AJAOFWI0`;

  return src;
};

export const cloudinaryVideo = (videoId) => {
  return `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/video/upload/vc_auto/rtg40200-video/${videoId}.mp4`;
};

export const cloudinary3dAsset = (assetId) => {
  const asset = cld.image(`${CLOUDINARY_IMAGE_ID_PREFIX}/${assetId}`);
  return asset.toURL();
  //return `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/${CLOUDINARY_IMAGE_ID_PREFIX}/${assetPath}`;
};

export const cloudinary3dFile = (assetId) => {
  return `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/raw/upload/${CLOUDINARY_IMAGE_ID_PREFIX}/${assetId}`;
}
