import { useEffect, useState } from 'react';

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(null);

  // Return a wrapped version of useState's setter function
  // that persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  useEffect(() => {
    // Get from local storage by key
    const item = localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    if (item) {
      setValue(JSON.parse(item));
    } else {
      setValue(initialValue);
    }
  }, []);

  return [storedValue, setValue];
}
