import React, { useState } from 'react';
import { NextSeo } from 'next-seo';
import { useAppContext } from '../src/context/app';
import Video from '../src/components/Video';
import styled from 'styled-components';
import * as Button from '../src/components/Button';
import Container from '../src/components/Container';
import { cloudinaryVideo } from '../src/helpers/CloudinaryHelpers';
const ScreenWrap = styled.div`
  height: 100%;
  background-color: var(--rtg-dark-blue);
`;

const VideoWrap = styled.div`
  position: relative;
`;

const ButtonWrap = styled.div`
  padding: 1.875rem 0;
  @media (min-width: 64em) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2.5rem 0;
    background: linear-gradient(
      to bottom,
      rgba(11.8, 23.1, 40, 0) 0%,
      rgba(11.8, 23.1, 40, 0.75) 100%
    );
  }
`;

const Buttons = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 0.625rem;
  grid-template-rows: 1fr 1fr 1fr;
  @media (min-width: 48em) {
    gap: 2.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

export default function Home() {
  const {availableColors } = useAppContext();
  console.log('availableColors', availableColors);
  const [playing, setPlaying] = useState('hero');

  const HERO_VIDEO = availableColors.includes('TEAL') ? 'M1_Hero_Teal' : 'M1_Hero';

  const playHero = () => {
    setPlaying('hero');
  };
  const playEco = () => {
    setPlaying('eco');
  };
  const playTech = () => {
    setPlaying('tech');
  };

  return (
    <>
      <NextSeo title="Home" />
      <ScreenWrap>
        <VideoWrap>
          {playing === 'hero' && (
            <Video
              autoplay={true}
              loop={true}
              muted={true}
              url={cloudinaryVideo(HERO_VIDEO)}
              contentType="video/mp4"
            />
          )}
          {playing === 'eco' && (
            <Video
              autoplay={true}
              loop={false}
              muted={true}
              url={cloudinaryVideo('M1_Fabric')}
              contentType="video/mp4"
              onFinished={() => playHero()}
            />
          )}
          {playing === 'tech' && (
            <Video
              autoplay={true}
              loop={false}
              muted={true}
              url={cloudinaryVideo('M1_TechVideo')}
              contentType="video/mp4"
              onFinished={() => playHero()}
            />
          )}

          <ButtonWrap>
            <Container>
              <Buttons>
                <li>
                  <Button.Green href="/categories">BUILD YOUR CONFIGURATION</Button.Green>
                </li>
                <li>
                  <Button.GreenUI clickHandler={() => playEco()}>PURE COMFORT STORY</Button.GreenUI>
                </li>
                <li>
                  <Button.GreenUI clickHandler={() => playTech()}>Sound &amp; Storage Story</Button.GreenUI>
                </li>
              </Buttons>
            </Container>
          </ButtonWrap>
        </VideoWrap>
      </ScreenWrap>
    </>
  );
}