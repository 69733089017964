import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import * as FormElements from './FormElements';
import * as Button from './Button';
import { useAppContext } from '../context/app';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  background-color: var(--dark-tyrian-blue);
  background-image: url('/images/M1Background.svg');
  opacity: 1; 
`;


const StoreNumberForm = () => {
  const { storeNumber, setStoreNumber, salesId, setSalesId } = useAppContext();
  const [errorMessage, setErrorMessage] = useState(null);
  const storeInput = useRef(null);

  useEffect(() => {
    if(storeInput.current) {
      storeInput.current.focus();
    }
  }, []);

  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    console.log(formJson);

    if (formJson.storeNumber.length) {
      setStoreNumber(formJson.storeNumber);
    } else {
      setErrorMessage('Store Number is required.');
    }
    if (formJson.salesId.length) {
      setSalesId(formJson.salesId);
    }
  }

  return (
    <Wrapper>
        <FormElements.FormWrapper>
        {storeNumber && <FormElements.Text>The current store number is: {storeNumber}</FormElements.Text>}
          <FormElements.Form method="post" onSubmit={handleSubmit}>
            <FormElements.Title>
              Enter Your Store Number
            </FormElements.Title>
            <FormElements.Field>
              <FormElements.FieldHeader>
                <FormElements.FieldLabel>
                  Store Number
                </FormElements.FieldLabel>
              </FormElements.FieldHeader>
              <FormElements.FieldInput name="storeNumber" ref={storeInput} />
            </FormElements.Field>
            <br/>
            <FormElements.Field>
              <FormElements.FieldHeader>
                <FormElements.FieldLabel>
                  Sales Associate ID
                </FormElements.FieldLabel>
              </FormElements.FieldHeader>
              <FormElements.FieldInput name="salesId" value={salesId} />
            </FormElements.Field>
            <FormElements.Actions>
              <Button.StandardUI type="submit">Submit</Button.StandardUI>
            </FormElements.Actions>
          </FormElements.Form>
          {errorMessage && <FormElements.Text>{errorMessage}</FormElements.Text>}
      </FormElements.FormWrapper>
    </Wrapper>
  );
};

export default StoreNumberForm;
