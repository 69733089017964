import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	max-width: 80rem;
	width: 100vw;
	padding-left: 1rem;
	padding-right: 1rem;
	margin: 0 auto;
	&.nopadding {
		padding-left: 0;
		padding-right: 0;
	}
`;

export default Container;