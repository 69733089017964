import React from 'react';
import VideoJS from './VideoJS';

export const Video = ({ ...fields }) => {
	const { url, contentType, autoplay, loop, muted, onFinished } = fields;
  const playerRef = React.useRef(null);
  const videoJsOptions = {
    autoplay: autoplay,
    muted: muted,
    controls: false,
    loop: loop,
    fluid: true,
    sources: [{
      src: url,
      type: contentType
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.play();
    
    // You can handle player events here, for example:
    player.on('waiting', () => {
      //videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      //videojs.log('player will dispose');
    });
    player.on('ended', () => {
      onFinished();
    });
  };
  return (
    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
  );
}

export default Video;