import React, { useCallback } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useAppContext } from '../context/app';
import { PRIVACY_NOTICE_URL, TERMS_OF_USE_URL, MODULAR_VERSION_LINK_URL, MODULAR_VERSION_LINK_TEXT} from '../../constants';
import * as Button from './Button';

import Container from './Container';

const Footer = styled.footer`
  background-color: var(--rtg-dark-blue);
  color: var(--white);
`;

const FooterInner = styled.div`
  padding: 1rem 0;
  text-align: center;
  display: grid;
  grid-template-areas:
    "actions"
    "meta"
  ;
  @media (min-width: 53em) {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const FooterMeta = styled.div`
  grid-area: meta;
  color: var(--white);
  text-align: center;
  padding: 0.25rem 0 0.625rem;
  font: var(--body-small);
  a {
    color: var(--white);
    text-decoration: none;
    &:after {
      content: '|';
      display: inline-block;
      padding: 0 0.75rem;
    }
    &:last-of-type:after {
      display: none;
    }
  }
  @media (min-width: 53em) {
    a {
      &:last-of-type:after {
        display: inline-block;
      } 
    }
  }
`;

const FooterActions = styled.div`
  grid-area: actions;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  white-space: nowrap;
`;

const FooterInfo = styled.span`
  padding: 0.25rem 0 0.625rem;
  opacity: 0.5;
  font: var(--body-small);
`;

const Copyright = styled.span`
  display: block;
  padding-top: .3125rem;
  @media (min-width: 53em) {
    display: inline;
    br {
      display: none;
    }
  }
`;

const SiteFooter = () => {
  const { storeNumber, setStoreNumber, zip, webUser } = useAppContext();
  const resetStoreNumber = useCallback(() => {
    setStoreNumber(null);
  }, [setStoreNumber]);
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Footer>
      <Container>
        <FooterInner>
          <FooterMeta>
          {!webUser && <a href={`${MODULAR_VERSION_LINK_URL}?store=${storeNumber}`}>{MODULAR_VERSION_LINK_TEXT}</a> }
            <a target="_blank" rel="noopener noreferrer" href={PRIVACY_NOTICE_URL}>Privacy Notice</a>
            <a target="_blank" rel="noopener noreferrer" href={TERMS_OF_USE_URL}>Terms of Use</a>
            <Copyright>Copyright &copy; {year} Rooms To Go. <br/>All Rights Reserved</Copyright>
          </FooterMeta>
          <FooterActions>
            {!zip && storeNumber && (
              <>
                Store Number:{' '}
                <Button.SmallUI clickHandler={resetStoreNumber}>
                  {storeNumber}
                </Button.SmallUI>
              </>
            )}
            {zip && (
              <FooterInfo>
                {zip} : {storeNumber}
              </FooterInfo>
              
            )}
          </FooterActions>
        </FooterInner>
      </Container>
    </Footer>
  );
};

export default SiteFooter;
