import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 29.125rem;
  @media (min-width: 30em) {
    padding: 0;
  }
`;

export const Form = styled.form``;

export const Title = styled.h2`
  text-align: center;
  font: var(--h-large);
  color: var(--white);
`;

export const TitleBlue = styled.h2`
  text-align: center;
  font: var(--h-large);
  color: var(--rtg-blue);
  letter-spacing: var(--h-xl-letterspacing);
`;

export const Text = styled.p`
  color: var(--electric-lime);
  margin-bottom: 1.5rem;
  text-align: center;
  @media (min-width: 48em) {
    text-align: left;
  }
`;

export const LegalText = styled.p`
  text-align: center;
  line-height: 1.5;
  color: var(--white);
  margin-bottom: 1.5rem;
  a {
    color: var(--white);
    text-decoration: underline;
    &:hover,
    &:focus-visible {
      color: var(--white);
    }
  }
`;

export const TextCentered = styled(Text)`
  text-align: center;
`;

export const Field = styled.div``;

export const FieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

export const FieldLabel = styled.label`
  font: var(--subheading);
  color: var(--electric-lime);
  text-transform: uppercase;
`;

export const FieldLabelBlue = styled.label`
  font: var(--subheading);
  color: var(--rtg-blue);
  text-transform: uppercase;
`;

export const FieldRequiredIndicator = styled.label`
  font: var(--body-small);
  color: var(--white);
`;

export const FieldInfo = styled.label`
  font: var(--body-small);
  color: var(--dark-tyrian-blue);
`;

export const FieldInput = styled.input`
  display: block;
  width: 100%;
  font: var(--body-small);
  padding: 0.625rem 1rem;
  color: #545454;
  background: var(--white);
  border: 1px solid var(--rtg-dark-blue);
  border-radius: 0.3125rem;
`;

export const Separator = styled.div`
  border-top: 1px solid var(--rtg-blue);
  margin: 2.5rem 0 1.25rem;
`;

export const Actions = styled.div`
  margin: 0.875rem 0 auto;
  text-align: center;
  button {
    background: var(--rtg-dark-blue);
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 48em) {
    text-align: left;
    button {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;
