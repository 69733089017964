import APIRoute from '../APIRoute';

class SendToCartRoute extends APIRoute {
  constructor(options) {
    const routeOptions = {
      auth: false,
      method: 'POST',
      template: `/api/cart`,
      ...options,
    };
    super(routeOptions);
  }
}

export default SendToCartRoute;
