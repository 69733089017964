import * as yup from 'yup';
import URITemplate from 'urijs/src/URITemplate';

const schema = yup.object().shape({
  auth: yup.boolean().default(false),
  baseURI: yup.string().default(''),
  body: yup.object(),
  method: yup
    .string()
    .oneOf(['GET', 'POST', 'PUT', 'DELETE', 'PATCH'])
    .required(),
  query: yup.object(),
  template: yup.string().required(),
});

class APIRoute {
  constructor(options) {
    let validated;
    try {
      validated = schema.validateSync(options);
    } catch (e) {
      throw new Error('Invalid APIRoute definition.');
    }

    this.auth = validated.auth;
    this.baseURI = validated.baseURI;
    this.body = validated.body;
    this.method = validated.method;
    this.query = validated.query;
    this.template = validated.template;
  }

  setBody(params) {
    this.body = params;
  }

  uriTemplateVariables() {
    return {
      baseURI: this.baseURI,
      ...this.query,
    };
  }

  uri() {
    const t = new URITemplate(`${this.baseURI}${this.template}`);
    return t.expand(this.uriTemplateVariables());
  }
}

export default APIRoute;
