export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';

export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

export const IS_STAGING =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging';

export const NOT_PRODUCTION =
  process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production';

export const CURRENT_VERSION = process.env.NEXT_PUBLIC_SENTRY_DIST || 'local';

export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || 'local';

export const PRIVACY_NOTICE_URL =
  'https://legal.roomstogo.com/rtg-online#contract-fxauo3tp2';
export const TERMS_OF_USE_URL =
  'https://legal.roomstogo.com/rtg-online#contract-7siltltgm';

export const MODULAR_VERSION_LINK_URL = `https://modulartwo.roomstogo.com`;
export const MODULAR_VERSION_LINK_TEXT = 'Switch to ModularTwo';

export const DOTCOM_URL = 'https://www.roomstogo.com/furniture/collections/modularone?keywords=modularone';

export const WEB_USER_STORE_NUMBER = '970';

export const SORTED_COLORS = ['CANTALOUPE', 'GRANITE', 'STONE', 'NAVY', 'TEAL'];

export const COLOR_NAME_MAP = {
  CANTALOUPE: 'Copper',
  GRANITE: 'Gray',
  NAVY: 'Indigo',
  STONE: 'Beige',
  TEAL: 'Teal',
};

export const COLOR_VALUE_MAP = {
  CANTALOUPE: 'rgba(144,84,68,255)', //'rgba(135,78,63,255)', //#874e3f
  GRANITE: 'rgba(95,94,91,255)', //#7f7d79
  NAVY: 'rgba(63,71,77,255)', //#3f474d
  STONE: 'rgba(192,178,167,255)', //#c0b2a7
  TEAL: 'rgba(68,96,105,255)', //#446069
};

export const CONFIGURATION_CATEGORIES = [
  {
    id: 'small',
    name: 'Small',
    title: 'Small room, **serious comfort.**',
    teaser:
      'Studio apartment? She shed? Here are some designs that make the most of every square foot.',
  },
  {
    id: 'medium',
    name: 'Medium',
    title: 'Not too big, not too small. **Just right.**',
    teaser:
      'Whether it’s for a home office or comfy living room, these designs have the flexibility you’re looking for.',
  },
  {
    id: 'large',
    name: 'Large',
    title: 'Large room? **Go big.**',
    teaser:
      'When the room gets bigger, so do our sectionals. See how more pieces add even more comfort to your room.',
  },
  {
    id: 'all',
    name: 'All',
    title: 'Here’s inspiration for spaces of all sizes.',
  },
];

export const CONFIGURATOR_GRID_PAD = 100;

export const CLOUDINARY_CLOUD_NAME =
  process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_IMAGE_ID_PREFIX =
  process.env.NEXT_PUBLIC_CLOUDINARY_IMAGE_ID_PREFIX;
export const CLOUDINARY_UPLOAD_PRESET = 'user_uploads';

/*
 rtgId is found by combining the DetailCategory and Variation from the api
 Most often, Variation is """, we only use it for CONSOLE+MEDIA (CONSOLEMEDIA)

  BUMPER OTTOMAN
  CONSOLE
  RAF CHAISE
  LAF CHAISE
  ARMLESS CHAIR
  WEDGE/CORNER
  RAF CHAIR
  LAF CHAIR
  SWIVEL
  TOSS (which we ignore)
  CONSOLEMEDIA

 */


export const PIECE_TYPE_MAPPING = [
  { id: 'corner', rtgId: 'WEDGE/CORNER' },
  { id: 'armless', rtgId: 'ARMLESS CHAIR' },
  { id: 'endLeft', rtgId: 'LAF CHAIR' },
  { id: 'endRight', rtgId: 'RAF CHAIR' },
  { id: 'chaiseLeft', rtgId: 'LAF CHAISE' },
  { id: 'chaiseRight', rtgId: 'RAF CHAISE' },
  { id: 'ottoman', rtgId: 'BUMPER OTTOMAN' },
  { id: 'console', rtgId: 'CONSOLE' },
  { id: 'consoleTech', rtgId: 'CONSOLEMEDIA' },
  { id: 'swivelChair', rtgId: 'SWIVEL' },
];

export const RTG_TO_INTERNAL_ID_MAPPING = PIECE_TYPE_MAPPING.reduce((rv, x) => {
  rv[x.rtgId] = x.id;
  return rv;
}, {});

export const INTERNAL_TO_RTG_ID_MAPPING = PIECE_TYPE_MAPPING.reduce((rv, x) => {
  rv[x.id] = x.rtgId;
  return rv;
}, {});

export const IN_BROWSER = typeof window !== 'undefined';
export const USER_AGENT = IN_BROWSER ? window.navigator?.userAgent : '';
export const IN_CHROME = USER_AGENT.includes('Chrome');
export const IN_SAFARI = USER_AGENT.includes('AppleWebKit') && !IN_CHROME;
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
export const IS_PRODUCTION_OR_STAGING = IS_PRODUCTION || IS_STAGING;