import { React, useEffect, useCallback } from 'react';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyle from '../src/components/GlobalStyles';
import SiteHeader from '../src/components/SiteHeader';
import SiteFooter from '../src/components/SiteFooter';
import DataLoader from '../src/components/DataLoader';
import StoreNumberWrapper from '../src/components/StoreNumberWrapper';
import { AppWrapper } from '../src/context/app';
import SEO from '../next-seo.config';
import { BASE_URL, IS_PRODUCTION_OR_STAGING } from '../constants';
import 'slick-carousel/slick/slick.css';
import FullStorySetup from '../src/components/FullStorySetup';
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../src/helpers/CloudinaryHelpers'

const Site = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
`;

const SiteMain = styled.main`
  position: relative;
`;

const queryClient = new QueryClient({});

function App({ Component, pageProps }) {
  const router = useRouter();
  const path = router.asPath === '/' ? router.asPath : `${router.asPath}/`;
  const url = `${BASE_URL}${path}`;

  useEffect(() => {
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Chair_Armless-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Chaise_left-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Chaise_Right-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Console_No_tech-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Console-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Corner-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Chair_Left_Arm-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Chair_Right_Arm-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/M1_Sofa_Copper_Ottoman-transformed.glb'));
    useGLTF.preload(cloudinary3dAsset('3d/New_Horizon_Melon-transformed.glb'));
  }, []);
  return (
    <>
      <Head>
        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <FullStorySetup />
      <DefaultSeo {...SEO} />
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <Site>
            <SiteHeader />
            <SiteMain>
              <StoreNumberWrapper>
                <DataLoader>
                  <Component {...pageProps} />
                </DataLoader>
              </StoreNumberWrapper>
            </SiteMain>
            <SiteFooter />
          </Site>
        </AppWrapper>
      </QueryClientProvider>
    </>
  );
}

export default App;
