import { NOT_PRODUCTION } from './constants';

const defaultSeoTags = {
  titleTemplate: '%s | Rooms To Go',
  defaultTitle: 'Rooms To Go',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    siteName: 'Rooms To Go',
  },
  twitter: {
    handle: '@RoomsToGo',
    site: '@RoomsToGo',
    cardType: 'summary_large_image',
  },
};

if (NOT_PRODUCTION) {
  defaultSeoTags.dangerouslySetAllPagesToNoIndex = true;
  defaultSeoTags.dangerouslySetAllPagesToNoFollow = true;
}

export default defaultSeoTags;
