import { createGlobalStyle } from 'styled-components';
import localFont from '@next/font/local'

/* FONT IMPORT */

const GilroyLight = localFont({
  src: '../../public/fonts/RTG-Gilroy-Light.woff2'
});
const GilroyLightItalic = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-LightItalic.woff2'
});
const GilroyRegular = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-Regular.woff2'
});
const GilroyRegularItalic = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-RegularItalic.woff2'
});
const GilroyMedium = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-Medium.woff2'
});
const GilroyMediumItalic = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-MediumItalic.woff2'
});
const GilroySemiBold = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-SemiBold.woff2'
});
const GilroySemiBoldItalic = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-SemiBoldItalic.woff2'
});
const GilroyBold = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-Bold.woff2'
});
const GilroyExtraBold = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-ExtraBold.woff2'
});
const GilroyExtraBoldItalic = localFont({ 
  src: '../../public/fonts/RTG-Gilroy-ExtraBoldItalic.woff2'
});

const GlobalStyle = createGlobalStyle`

/* VARS */

:root {
  /* VARS COLORS */
  --white: #FFFF;
  --eggshell: #FAFAFA;
  --offwhite: #F2F2F2;
  --lighter-gray: #ECECEC;
  --light-gray: #DCDCDC;
  --medium-gray: #E7E7E7;
  --medium-dark-gray: #C2C2C2;
  --dark-gray: #545454;
  --gray: #888888;
  --repreve-green: #86B941;
  --electric-lime: #9CE80A;
  --electric-lime-light: #D7F69D;
  --muted-violet: #977CA6;
  --dark-tyrian-blue: #1E3B66;
  --rtg-blue: #0050B5;
  --rtg-dark-blue: #07263B;
  --rtg-yellow: #FFC600;
  --rtg-red: #C00D1E;
  --rtg-green: #169162;

  /* VARS TEXT */
  --body-small: normal 0.875rem/1.45 ${GilroyRegular.style.fontFamily}, sans-serif;
  --body-medium: normal 1rem/1.45 ${GilroyRegular.style.fontFamily}, sans-serif;
  --body-medium-semibold: normal 1rem/1.45 ${GilroySemiBold.style.fontFamily}, sans-serif;
  --body-medium-letterspacing: 0.0225em;
  --body-medium-bold: normal 1rem/1.45 ${GilroyBold.style.fontFamily}, sans-serif;
  --body-large: normal 1.375rem/1.7272 ${GilroyRegular.style.fontFamily}, sans-serif;
  --subheading: normal 0.6875rem/1.5 ${GilroyExtraBold.style.fontFamily}, sans-serif;
  --h-small: normal 1.25rem/1.5 ${GilroyMedium.style.fontFamily}, sans-serif;

  --h-small-regular: normal 1.25rem/1.5 ${GilroyRegular.style.fontFamily}, sans-serif;
  --h-small-extra-bold: normal 1.25rem/1.5 ${GilroyExtraBold.style.fontFamily}, sans-serif;

  --h-small-bold: normal 1.25rem/1 ${GilroyBold.style.fontFamily}, sans-serif;

  --h-medium: normal 1.5rem/1.3 ${GilroyRegular.style.fontFamily}, sans-serif;
  --h-medium-bold: normal 1.5rem/1.3 ${GilroyBold.style.fontFamily}, sans-serif;

  --h-large: normal 2rem/1.3 ${GilroyMedium.style.fontFamily}, sans-serif;
  --h-large-extra-bold: normal 2rem/1.3 ${GilroyExtraBold.style.fontFamily}, sans-serif;

  --h-xl: normal 2.5rem/1.3 ${GilroyMedium.style.fontFamily}, sans-serif;
  --h-xl-semibold: normal 2.5rem/1.3 ${GilroySemiBold.style.fontFamily}, sans-serif;
  --h-xl-letterspacing: -0.00125em;
  --h-xxl: normal 3.125rem/1.3 ${GilroyRegular.style.fontFamily}, sans-serif;
  --h-xxxl: normal 4.9375rem/1.3 ${GilroyExtraBold.style.fontFamily}, sans-serif;
  
  --extra-bold-header: normal 3rem/1.3 ${GilroyExtraBold.style.fontFamily}, sans-serif;
  --bold-header: normal 5.375rem/1.3 ${GilroyExtraBold.style.fontFamily}, sans-serif;
  --light-header: normal 2.5rem/1.3 ${GilroyLight.style.fontFamily}, sans-serif;

  --huge-header: normal 18.75rem/1 ${GilroyExtraBold.style.fontFamily}, sans-serif;

  --button: normal 0.875rem/1 ${GilroySemiBold.style.fontFamily}, sans-serif;
  --button-letterspacing: 0.025em;
}

/* GLOBAL ELEMENTS */

*, *::before, *::after { box-sizing: border-box;}


html, body { 
  background: white; 
  font: var(--body-medium);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: var(--body-medium-letterspacing);
  color: var(--dark-gray);
  margin: 0;
  padding: 0;
  &:has(.configurations-list) {
    overflow-x: hidden;
  }
}
html {
  background-color: var(--rtg-dark-blue);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

button { 
  cursor: pointer; 
  background: none;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
}

`;

export default GlobalStyle;
