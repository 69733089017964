import axios from 'axios';
import APIError from './APIError';

/**
 * Take a response object (from Axios) and check to see if the HTTP status code
 * is "success" (between 200 and 300).
 * @param {Axios Response} response - A response object from a request made by Axios.
 */
const checkHTTPStatusCode = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }

  throw new APIError(response);
};

/** Class representing an API request. */
class APIRequest {
  /**
   * Create an APIRequest.
   * @param {Route} route - The API route to call.
   */
  constructor(route) {
    this.route = route;
    this.axiosInstance = axios.create();
    this.buildHeaders();
  }

  /**
   * Run the request.
   */
  run() {
    this.buildRequestOptions();

    return this.axiosInstance.request(this.options).then(checkHTTPStatusCode);
  }

  /**
   * Assemble all the request information into an options object that
   * will be passed to Axios.
   */
  buildRequestOptions() {
    this.options = {
      url: this.route.uri(),
      method: this.route.method,
      headers: this.headers,
      perfToken: this.perfToken,
      debugType: 'api',
      validateStatus: () => true,
    };

    if (this.route.body) {
      this.options.data = JSON.stringify(this.route.body);
    }
  }

  buildHeaders() {
    this.headers = {
      'Content-Type': 'application/json',
      'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
    };
  }
}

export default APIRequest;
