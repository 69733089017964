import { useAppContext } from '../context/app';
import StoreNumberForm from './StoreNumberForm';

const StoreNumberWrapper = ({ children }) => {
  const { storeNumber } = useAppContext();

  if (storeNumber) {
    console.log(`Store number is set to ${storeNumber}.`);
    return <>{children}</>;
  }

  return <StoreNumberForm />;
};

export default StoreNumberWrapper;
