const versionKey = [
  process.env.SERVERLESS_ENVIRONMENT,
  process.env.CURRENT_VERSION,
]
  .filter((i) => i)
  .join('-');

const QueryConfig = {
  cacheKeys: {
    storeProducts: `storeProducts-${versionKey}`,
  },
};

export default QueryConfig;
